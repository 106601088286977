<template>
    <div class="card-send-success-container" :style="{height:scrollHeight+'px'}">
        <div class="success-icon-container">
            <div class="icon">
                <img src="https://api.mengjingloulan.com/storage/wechat/new-user-center/send-success-icon.png"/>
            </div>
            <div class="status-text">
                {{title}}
            </div>
            <div class="success-text">{{this.text}}</div>
        </div>

        <div class="middle-title">
            <title-render></title-render>
        </div>

        <div class="card-container">
            <div class="card-cover" v-for="(item,index) in lists">
                <img :src="item.cover" style="height: 165px;width: 100%;" @click="goToBuyPage(item.id)"/>
            </div>
        </div>

        <div style="width: 100%;height: 70px;"></div>

        <div class="detail-buttons">
            <div class="buttons" @click="goToHomePage">
                <div class="use">
                    确定
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import TitleRender from '@/components/common/title-render'

  export default {
    name: 'success-page',
    components: {TitleRender},
    mounted () {
      let query = this.$route.query
      document.title = query.title || '转出成功'
      this.title = query.title
      this.text = query.text || '恭喜您，转赠成功'
      this.card_id = query.card_id || 0
      this.backType = query.backType || 1
      this.getRecommend()
    },
    data () {
      return {
        lists: [],
        text: '',
        title: '',
        card_id: 0,
        backType: 1,
        scrollHeight:window.innerHeight
      }
    },
    methods: {
      goToHomePage () {
        if (this.backType == 1) {
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/couponCard/home',
          })
        } else if (this.backType == 2) {
          this.$platform.wxsdk.wxRoute({
            type: 'redirectTo',
            url: '/web/couponCard/card-use?query=' + encodeURIComponent(JSON.stringify({order_id: this.card_id})),
          })
        }

      },
      getRecommend () {
        this.$api.couponCard.cardRecommendList().then(response => {
          this.lists = response.data
        })
      },
      goToBuyPage (id) {
        let query = {
          id: id
        }
        let options = encodeURIComponent(JSON.stringify(query))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/couponCard/card-buy?query=' + options,
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-send-success-container {
        width: 100vw;
        min-height: 100vh;
        background: #fff;
        flex-wrap: wrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .success-icon-container {
            width: 100%;
            margin-top: 60px;

            .icon {
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    width: 109px;
                    height: 109px;
                }
            }

            .status-text {
                width: 100%;
                font-size: 14px;
                color: #333333;
                display: flex;
                justify-content: center;
                margin-top: 23px;
            }

            .success-text {
                color: #939393;
                font-size: 12px;
                margin-top: 8px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .middle-title {
            width: 100%;
            margin-top: 45px;
        }

        .card-container {
            margin-top: 24px;
            padding: 0 15px;

            img {
                width: 100%;
                margin-bottom: 9.5px;
            }
        }

        .detail-buttons {
            width: 100%;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            background: #fff;
            border-top: #F6f6f6 solid 1px;
            display: flex;
            align-items: center;
            justify-content: center;

            .buttons {
                flex: 1;
                display: flex;
                padding: 0 15px;

                .use {
                    width: 100%;
                    height: 48px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    -webkit-border-radius: 6px;
                    -moz-border-radius: 6px;
                    border-radius: 6px;
                    font-size: 14px;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>